.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-button{
  font-size: 20px;
  font-weight: bold;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  background-color: #7749f8;
  padding: 0.1em 1em;
  border-radius: 1.25em;
  width: 100%;
  color: #eee;
  border: 0;
  height: 3.2em;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  }
  
#little-button {
    background-color: #333a4b;
    color: white;
    font-weight: bold;
    margin: auto;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
  }
  #login-logo {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 10px;
      padding-bottom: 100px;
      height: 54px;
      margin-top: 50px;
  }
  #login-logo, #login-content {
      text-align: center;
      width: 400px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
  }
  body {
      font-family: Tahoma, Verdana, Arial, Helvetica;
      font-size: 10pt;
      color: #000;
  }
  
  #login-content {
      padding: 20px;
      padding-top: 40px;
      background-color: #fff;
      border: 2px solid #CBD2D9;
      border-radius: 4px;
      margin-top: 110px;
  }
  h1 {
      margin-top: 0;
      color: #000000;
      font-size: 16pt;
      font-family: Tahoma, Verdana, Arial, Helvetica;
      font-weight: bold;
  }

h2 {
    margin-top: 0;
    color: #000000;
    font-size: 12pt;
    font-family: Tahoma, Verdana, Arial, Helvetica;
    font-weight: bold;
}

h3 {
  margin-top: 0;
  color: #000000;
  font-size: 10pt;
  font-family: Tahoma, Verdana, Arial, Helvetica;
  font-weight: bold;
}

  .screenContainer {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container {
    position: relative;
    /* The container determains the size. */
    width: 1000px;
    height: 600px;
  }
  
  .container > .loading-overlay {
    /* We'll render the overlay on top of the Unity Application. */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: grey;
    /* We'll set the following Flex properties in order to center the text. */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .container > .unity {
    /* The Unity Application matches it size to the container. */
    width: 100%;
    height: 100%;
  }

.colorOrange
{
  color: #FF3D00
}

.loader {
  width: 48px;
  height: 48px;
  border: 3px solid #FFF;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 3px solid;
  border-color: #FF3D00 transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 